export const firebaseConfig = {
    "projectId": "guidde-dev-sasi-auto",
    "appId": "1:1046321341175:web:67213a5daa14df35bba9f2",
    "databaseURL": "https://guidde-dev-sasi-auto-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-dev-sasi-auto.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyAd3iQhgcpqOp0ASuIQ_B0N2tJ5hfe9mR0",
    "authDomain": "guidde-dev-sasi-auto.guidde.com",
    "messagingSenderId": "1046321341175",
    "environment": "guidde-dev-sasi-auto"
}

export const stripeKey =
'None'
export const stiggClientKey =
'123'
export const facebookAppId = '3155065591456766'
export const celloConfig = {
    script: 'https://assets.sandbox.cello.so/app/latest/cello.js',
    attributionScript: 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js',
    productId: 'stage-app.guidde.com'
}

export const STIGG_CLIENT_KEY =
'123'
export const TEST =
'2'
export const tapfiliateAccountId =
'38971-9bf427'