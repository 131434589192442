import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useLayers } from 'UI/Routes/quick-guidde/CanvasEditor'
import { AddStepMenuItem, DEFAULT_STEP } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'

import { FileUploader, LimitationDialog } from 'UI/Components'
import { type FileMetadataType, PresentationSlidesDialog } from './PresentationSlidesDialog'

import { ReactComponent as ImportFromFile } from 'assets/icons/import-from-file.svg'

import { type QuickGuiddeType, type StepType, Shape } from 'app/types'

import { setQuickGuiddeSteps } from 'ducks'
import { logToAnalytics, uuid } from 'modules'

import {
    useAuth,
    useBoolean,
    useConfiguration,
    useDataMutation,
    useNotification,
    useServiceUsage,
    useSessionStorage
} from 'hooks'

type ExtraAPIParamsType = {
    fileType: string
}

type SlidesNumberPayloadType = { playbookId: string; requestId: string } & ExtraAPIParamsType

type ThumbsPayloadType = {
    playbookId: string
    requestId: string
    width: number
    height: number
} & ExtraAPIParamsType

type SelectedPayloadType = {
    selectedIndexes: Array<number>
    playbookId: string
    requestId: string
    width: number
    height: number
    selectedAll?: boolean
}

type ResponseThumbType = {
    slides: SlidesType
}

type ResponseSelectedType = {
    slides: Array<{
        img: string
        index: number
        width: number
        height: number
    }>
}

type SlidesType = Array<{
    img: string
    index: number
}>

const pdfFormat = 'application/pdf'
const pptFormat = '.pptx, .ppsx'

const allowedFileTypes = {
    ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    pdf: 'application/pdf'
}

const getFileType = (contentType?: string) => {
    if (!contentType) return ''

    const result = Object.entries(allowedFileTypes).find(([_, value]) => value === contentType)?.[0]
    return result || ''
}

const pdfTexts = {
    dialogTitle: 'Insert Pages',
    selectAllButtonLabel: 'Select all pages',
    onSubmitButtonLabel: 'IMPORT PAGES',
    selectedCountLabel: {
        one: 'page',
        other: 'pages'
    }
}

const pptTexts = {
    dialogTitle: 'Insert slides',
    selectAllButtonLabel: 'Select all slides',
    onSubmitButtonLabel: 'IMPORT SLIDES',
    selectedCountLabel: {
        one: 'slide',
        other: 'slides'
    }
}

type Props = {
    onDone: () => void
    playbook: QuickGuiddeType
}

export const PresentationSteps = ({ onDone, playbook }: Props) => {
    const dispatch = useDispatch()

    const { showErrorNotification } = useNotification()

    const { isFreePlan } = useServiceUsage()
    const { stepNumbering, watermark, image, backgroundRectangle } = useLayers()

    const fileUploading = useBoolean()
    const slidesLimitDialog = useBoolean()

    const [uploadProgress, setUploadProgress] = useState(0)

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const [savedInput] = useSessionStorage<number | null>('guiddeSavedDuration', null)

    const {
        kind,
        windowDimensions,
        windowDimensions: { innerWidth, innerHeight }
    } = steps[activeStep] || {}

    const { uid } = useAuth()

    const [requestId, setRequestId] = useState(uuid())
    const [fileMetadata, setFileMetadata] = useState<FileMetadataType | null>(null)

    const setFileUploading = fileUploading.set

    const setInitialStates = useCallback(() => {
        setFileMetadata(null)
        setUploadProgress(0)
        setRequestId(uuid())
        setFileUploading(false)
    }, [setFileUploading])

    const { pptImport, isConfigurationLoading } = useConfiguration()

    const $parseSlides = useDataMutation<ThumbsPayloadType, ResponseThumbType, Error>(
        '/p/v1/ppt-thumbs',
        'POST',
        {
            onFailure: (error, input) => {
                console.error('[/c/v1/ppt-thumbs] error: ', error, input)
            }
        }
    )

    const $selectedSlides = useDataMutation<SelectedPayloadType, ResponseSelectedType, Error>(
        '/p/v1/ppt-selected',
        'POST',
        {
            onFailure: (error, input) => {
                console.error('[/p/v1/ppt-selected] error: ', error, input)
            }
        }
    )

    const $slidesNumber = useDataMutation<SlidesNumberPayloadType, { slidesNumber: number }, Error>(
        '/p/v1/ppt-slides-number',
        'POST'
    )

    const onImportSlides = async (selectedIndexes: Array<number>) => {
        const fullSizeImages = await getFullSizeImages(selectedIndexes)

        const drawnScreenshots =
            $parseSlides.data?.slides
                ?.filter(({ index }) => selectedIndexes.includes(index))
                .map(it => it.img) || []

        const titleSteps = generateTitleStepsWithImage(fullSizeImages, drawnScreenshots)
        const stepsWithTitleSteps = generateStepsListWithTitleSteps(titleSteps)

        dispatch(setQuickGuiddeSteps(stepsWithTitleSteps))

        setInitialStates()
        onDone()
    }

    const generateStepsListWithTitleSteps = (titleSteps: Array<StepType>) => {
        const isLastStepActive = kind === 'end'
        const posDelta = isLastStepActive ? 0 : 1
        const newIndex = activeStep + posDelta

        return [
            ...steps.slice(0, newIndex),
            ...titleSteps,
            ...steps.slice(newIndex).map((step, stepIndex) => ({
                ...step,
                // update step numbering if the one exists
                layers: step.layers.map(layer => {
                    if (layer.type !== Shape.CircleWithText) return layer

                    return {
                        ...layer,
                        text: {
                            ...layer.text,
                            title: (activeStep + 2 + stepIndex).toString().padStart(2, '0') // update step numbering of all the steps after newly added
                        }
                    }
                })
            }))
        ]
    }

    const getFullSizeImages = async (selectedIndexes: Array<number>) => {
        const fullSizeImages = await $selectedSlides.mutate({
            height: innerHeight,
            width: innerWidth,
            selectedIndexes,
            selectedAll: selectedIndexes.length === Number($parseSlides.data?.slides?.length),
            playbookId: playbook.id,
            requestId
        })

        return fullSizeImages?.slides || []
    }

    const generateTitleStepsWithImage = (
        images: ResponseSelectedType['slides'],
        drawnScreenshots: Array<string>
    ): Array<StepType> => {
        const needStepNumbering = steps.some(step =>
            step.layers.some(layer => layer.type === Shape.CircleWithText)
        )

        return images.map((img, i) => ({
            ...DEFAULT_STEP,
            id: uuid(),
            timeStamp: Date.now(),
            windowDimensions,
            drawnScreenshot: drawnScreenshots[i] || '',
            ...(savedInput && { duration: savedInput }),
            layers: [
                backgroundRectangle,
                {
                    ...image,
                    x: (innerWidth - img.width) / 2,
                    y: (innerHeight - img.height) / 2,
                    url: img.img,
                    width: img.width,
                    height: img.height,
                    isBackground: false
                },
                ...(isFreePlan ? [watermark] : []),
                // if any step has step numbering - new step should have it as well
                ...(needStepNumbering ? [stepNumbering] : [])
            ]
        }))
    }

    return (
        <>
            <FileUploader
                onDone={(_, metadata) => {
                    const fileType = getFileType(metadata.type)

                    if (!fileType) {
                        showErrorNotification('Allowed file types: .pptx, .ppsx, .pdf')
                        return
                    }

                    fileUploading.setFalse()

                    $slidesNumber
                        .mutate({
                            requestId,
                            playbookId: playbook.id,
                            fileType
                        })
                        .then(data => {
                            if (!pptImport || !data?.slidesNumber) return

                            if (data.slidesNumber > pptImport.maxSlidesCount) {
                                setInitialStates()
                                slidesLimitDialog.setTrue()
                            } else {
                                $parseSlides.mutate({
                                    requestId,
                                    playbookId: playbook.id,
                                    width: innerWidth,
                                    height: innerHeight,
                                    fileType
                                })
                            }
                        })
                }}
                onFileChange={file => {
                    if (!file) return

                    fileUploading.setTrue()
                    setFileMetadata({ name: file.name, size: file.size, type: file.type })
                }}
                onProgressChange={progress => setUploadProgress(Math.trunc(progress))}
                maxFileSizeInMb={Number(pptImport?.maxFileSizeInMb)}
                onSizeLimit={setInitialStates}
                filename="file"
                previewWidth={24}
                accept={`${pdfFormat},${pptFormat}`}
                previewHeight={24}
                isTempStorage
                hideAttachFile
                hidePreview
                hideProgress
                storagePath={`pptUploads/${uid}/${playbook.id}/${requestId}`}
                labelComponent={
                    <AddStepMenuItem
                        onClick={() => {
                            logToAnalytics('importSteps_importFromPPTBtn_clicked', {
                                playbookId: playbook.id
                            })
                        }}
                        disabled={isConfigurationLoading}
                        title="Import from file"
                        Icon={ImportFromFile}
                    />
                }
            />
            {fileMetadata && (
                <PresentationSlidesDialog
                    isOpen={true}
                    texts={fileMetadata?.type === allowedFileTypes.pdf ? pdfTexts : pptTexts}
                    slides={$parseSlides.data?.slides || []}
                    playbookId={playbook.id}
                    fileMetadata={fileMetadata}
                    isConverting={$selectedSlides.isLoading}
                    uploadProgress={uploadProgress}
                    skeletonsCount={$slidesNumber.data?.slidesNumber}
                    isUploading={fileUploading.isTrue || $slidesNumber.isLoading}
                    isParsing={$parseSlides.isLoading}
                    onClose={setInitialStates}
                    onImport={onImportSlides}
                />
            )}

            <LimitationDialog
                isOpen={slidesLimitDialog.isTrue}
                onClose={slidesLimitDialog.setFalse}
                title="Slides alert"
                text={
                    <>
                        The selected media file is too large (over {pptImport?.maxSlidesCount}{' '}
                        slides).
                    </>
                }
            />
        </>
    )
}
