import { useCallback, useEffect, useMemo, useState } from 'react'
import { doc } from 'firebase/firestore'

import {
    type SetupIntentResult,
    type Stripe,
    type StripeElements,
    loadStripe
} from '@stripe/stripe-js'
import { type BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'
import { string as yupString } from 'yup'

import {
    useNotification,
    useAuth,
    useDataMutation,
    useBoolean,
    planTypes,
    useServiceUsage,
    type PlanIdType
} from 'hooks'

import { stripeKey } from 'env'

import {
    type ChargeTrackDataType,
    logToAnalytics,
    sendTapfiliateConversion,
    db,
    getReferralCode,
    generalErrorMessage
} from 'modules'
import { type PaymentMethodMapped, type StripeType } from 'app/types'
import { type ApplyCouponProps, type CouponResDataType } from 'UI/Components'
import { useDocumentData } from 'react-firebase-hooks/firestore'

type StartCheckoutProps = {
    planId: string
    addons?: Array<{
        addonId: string
        quantity?: number
    }>
    unitQuantity: number
    billingPeriod: BillingPeriod
    email?: string
}

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

type UpdatePlanProps = Optional<StartCheckoutProps, 'addons' | 'billingPeriod' | 'unitQuantity'>

export type ChargeResType = {
    code: number
    message: string
    clientSecret?: string | null
    paymentMethodId?: string | null
    trackData: ChargeTrackDataType
    currentPlan?: string
    cancellationDate?: string
    amountOfSeatsDowngraded?: string | null
}

export type ChargeCustomerProps = {
    clientSecret: string
    paymentMethodId?: string
    setupIntent?: string
}

export type UpdatePlanType = (update: UpdatePlanProps) => Promise<ChargeResType | undefined>

type DateRange = {
    start?: Date
    end?: Date
}

type Money = {
    amount: number
    currency: string
}

type SubscriptionPreviewProration = {
    prorationDate: Date
    credit: Money
    debit: Money
}

export type SubscriptionPreview = {
    subscription: {
        subTotal: Money
        total: Money
    }
    subTotal: Money
    total: Money
    billingPeriodRange: DateRange
    proration?: SubscriptionPreviewProration
}

type PriceChangeType = {
    percent: number
    amount: number
}

export type EstimateSubscriptionResType = {
    subscription: SubscriptionPreview
    tax?: PriceChangeType
    discount?: PriceChangeType
}

export type TaxType = {
    percentage: number
    country: string | null
}

type CheckoutResType = {
    status: number
    message: string
    clientSecret: string
    taxes: Array<TaxType>
} & EstimateSubscriptionResType

type UpdateCardResType = {
    status: number
    message: string
    clientSecret?: string
}

export type StartTrialType = (planId: string) => Promise<void>
export type StartCheckoutType = (props: StartCheckoutProps) => Promise<CheckoutResType | undefined>

export type SubmitStripeFormType = (props: {
    elements: StripeElements | null
    stripe: Stripe | null
    name: string
    onError?: (res: SetupIntentResult) => void
    onSuccess?: (res: SetupIntentResult) => void
}) => Promise<void | SetupIntentResult>

export type UserToDowngradeType = {
    email: string
    uid: string
}

export type EstimateSubscriptionInput = {
    planId: string
    billingPeriod: string
    unitQuantity: number
    promotionCode?: string
    country?: string
}

type Props = {
    totalLicenses?: number
}

type TDowngradeToFreePlanLogEvent = {
    activePlanId: PlanIdType
    planName: string
    userSeatsQuota: number
    unitQuantity?: number
    nextBillingDateMs?: number
}
const generateDowngradeToFreePlanLogEventData = ({
    activePlanId,
    planName,
    userSeatsQuota,
    unitQuantity,
    nextBillingDateMs
}: TDowngradeToFreePlanLogEvent) => ({
    planIdDowngradedFrom: activePlanId,
    planIdDowngradedTo: planTypes.individual,
    planNameDowngradedFrom: planName,
    amountOfSeatsDowngraded: userSeatsQuota || 'unlimited',
    ...(userSeatsQuota !== unitQuantity && {
        amountOfSeatsDowngradedFrom: userSeatsQuota || 'unlimited',
        amountOfSeatsDowngradedTo: unitQuantity || 'unlimited'
    }),
    downgradeReason: 'User downgrade',
    ...(nextBillingDateMs && { effectiveEndDate: new Date(nextBillingDateMs) })
})

type TCanLogDowngradeEvent = { isTrialPlan: boolean; activePlanId: PlanIdType }

const canLogDowngradeEvent = ({ isTrialPlan, activePlanId }: TCanLogDowngradeEvent) => {
    const currentPlanIsEnterprise = activePlanId === planTypes.enterprise
    const currentPlanIsBusiness = activePlanId === planTypes.business
    const currentPlanIsPro = activePlanId === planTypes.pro

    if (currentPlanIsPro && !isTrialPlan) return true

    if (currentPlanIsBusiness && !isTrialPlan) return true

    if (currentPlanIsEnterprise) return true

    return false
}

const confirmPayment = async (clientSecret: string, paymentMethodId: string) => {
    const stripe = await loadStripe(stripeKey)

    return stripe
        ?.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId
        })
        ?.then(res => {
            if (res.error) {
                throw res.error.message
            }
        })
}

export const usePayment = ({ totalLicenses }: Props = {}) => {
    const { showErrorNotification, showSuccessNotification } = useNotification()

    const { email, orgId } = useAuth()

    const formLoading = useBoolean()

    const {
        activePlanId,
        planName,
        userSeatsQuota,
        isTrialPlan,
        nextBillingDateMs,
        billingEmail: billingEmailActive
    } = useServiceUsage()

    const [estimateSubscriptionData, setEstimateSubscriptionData] =
        useState<EstimateSubscriptionResType>()
    const [stripePromise, setStripeData] = useState<Stripe | null>(null)
    const [billingEmail, setBillingEmail] = useState(billingEmailActive || email)
    const [billingEmailError, setBillingEmailError] = useState('')

    useEffect(() => {
        loadStripe(stripeKey).then(setStripeData)
    }, [])

    const [data] = useDocumentData(doc(db, `stripe/${orgId}`))

    const stripe = useMemo(() => (data || {}) as StripeType, [data])

    const $updateCard = useDataMutation<{ email: string }, UpdateCardResType, Error>(
        '/f/v1/updateCard',
        'POST'
    )
    const $startCheckout = useDataMutation<StartCheckoutProps, CheckoutResType, Error>(
        '/f/v1/startCheckout',
        'POST'
    )
    const $chargeCustomer = useDataMutation<ChargeCustomerProps, ChargeResType, Error>(
        '/f/v1/charge-customer',
        'POST'
    )
    const $updatePlan = useDataMutation<UpdatePlanProps, ChargeResType, Error>(
        '/f/v1/updatePlan',
        'POST'
    )
    const $updateEmail = useDataMutation<{ billingEmail: string }, void, Error>(
        '/f/v1/updateEmail',
        'POST'
    )
    const $estimateSubscription = useDataMutation<
        EstimateSubscriptionInput,
        EstimateSubscriptionResType,
        Error
    >('/c/v1/estimate-subscription', 'POST', {
        onSuccess: data => {
            setEstimateSubscriptionData(data)
        },
        onFailure: () => {
            showErrorNotification(generalErrorMessage)
        }
    })
    const $startTrial = useDataMutation<
        { planId: string; email: string },
        { status: number; message: string },
        Error
    >('/f/v1/startTrial', 'POST', {
        onFailure: () => {
            showErrorNotification(generalErrorMessage)
        }
    })

    const {
        mutate: applyCoupon,
        isLoading: isCouponApplying,
        data: couponResData,
        reset: resetCouponData
    } = useDataMutation<ApplyCouponProps, CouponResDataType, Error>('/c/v1/apply-coupon', 'POST', {
        disableDefaultErrorHandling: true
    })

    const { mutate: fetchUsersToDowngrade, data: usersToDowngrade } = useDataMutation<
        void,
        Array<UserToDowngradeType>,
        Error
    >(totalLicenses ? `/c/v1/get-users-for-downgrade?seatsNewAmount=${totalLicenses}` : '', 'GET')

    const clientSecret = $startCheckout.data?.clientSecret
    const updateCardSecret = $updateCard.data?.clientSecret

    const mutateUpdateEmail = $updateEmail.mutate

    const updateEmail = useCallback(
        async (billingEmail: string, callback?: () => void) => {
            logToAnalytics('change_billing_email', {
                billingEmail
            })
            const res = await mutateUpdateEmail({
                billingEmail
            })

            if (res) {
                showSuccessNotification('Billing email was changed')
                callback?.()
                return
            }
            showErrorNotification(
                'We encountered a technical issue while processing your request. Please try again later or contact support for assistance'
            )
        },
        [mutateUpdateEmail, showErrorNotification, showSuccessNotification]
    )

    const mutateUpdateCard = $updateCard.mutate
    const startCardUpdate = useCallback(() => {
        return mutateUpdateCard({
            email
        })
    }, [mutateUpdateCard, email])

    const mutateStartCheckout = $startCheckout.mutate
    const chargeCustomer = useCallback(
        async (props: ChargeCustomerProps) => {
            const res = await $chargeCustomer
                .mutate({
                    ...props,
                    ...(billingEmail && billingEmail !== billingEmailActive && { billingEmail })
                })
                .catch(e => {
                    logToAnalytics('chargeCustomerFailed', { error: e })
                    throw e
                })

            if (!res) return

            if (!res.clientSecret) {
                // user was successfully charged without 3DS confirmation
                logToAnalytics('chargeCustomerSucceded', res)
                sendTapfiliateConversion(res.trackData)

                return res
            }

            const paymentMethodId = res.paymentMethodId || props.paymentMethodId

            if (!paymentMethodId) {
                throw new Error('Something went wrong, please try again or contact support')
            }

            await confirmPayment(res.clientSecret, paymentMethodId).catch(e => {
                logToAnalytics('chargeCustomerConfirmationFailed', { error: e })
                throw e
            })

            // user was successfully charged after 3DS confirmation
            logToAnalytics('chargeCustomerConfirmationSucceded', res)
            showSuccessNotification('Payment was successfully confirmed!')
            sendTapfiliateConversion(res.trackData)

            return res
        },

        [$chargeCustomer, billingEmail, billingEmailActive, showSuccessNotification]
    )

    const startCheckout = useCallback(
        async ({ addons, billingPeriod, planId, unitQuantity }: StartCheckoutProps) => {
            resetCouponData()

            return mutateStartCheckout({
                email,
                billingPeriod,
                addons,
                planId,
                unitQuantity
            })
        },

        [resetCouponData, mutateStartCheckout, email]
    )

    const mutateUpdatePlan = $updatePlan.mutate

    const updatePlan = useCallback<UpdatePlanType>(
        async ({ addons, billingPeriod, planId, unitQuantity }: UpdatePlanProps) => {
            logToAnalytics(`send_request_for_changing_plan_to_${planId}`, {
                billingInterval: billingPeriod,
                addons
            })

            const res = await mutateUpdatePlan({
                email,
                addons,
                billingPeriod,
                planId,
                unitQuantity
            }).catch(e => {
                logToAnalytics('updatePlanFailed', e)
                throw e
            })

            if (!res) return

            if (!res?.clientSecret) {
                if (planId === planTypes.individual) {
                    const logEventIsAvailable = canLogDowngradeEvent({ isTrialPlan, activePlanId })

                    if (logEventIsAvailable) {
                        const logEventData = generateDowngradeToFreePlanLogEventData({
                            activePlanId,
                            planName,
                            userSeatsQuota,
                            unitQuantity,
                            nextBillingDateMs
                        })

                        logToAnalytics('planDowngraded', logEventData)
                    }
                } else {
                    const referralId = await getReferralCode()
                    logToAnalytics('updatePlanSucceded', { referralId, ...res })
                }

                // user was successfully charged without 3DS confirmation
                showSuccessNotification(
                    'We’ve received your request. The changes will be applied shortly.'
                )
                sendTapfiliateConversion(res.trackData)

                return res
            }

            if (!res.paymentMethodId) {
                showErrorNotification(
                    'Something went wrong. Please try again later or contact support'
                )

                return
            }

            await confirmPayment(res.clientSecret, res.paymentMethodId).catch(e => {
                logToAnalytics('updatePlanConfirmationFailed', e)
                throw e
            })

            // user was successfully charged after 3DS confirmation
            showSuccessNotification('Payment was successfully confirmed!')
            logToAnalytics('updatePlanConfirmationSucceded', res)
            sendTapfiliateConversion(res.trackData)

            return res
        },
        [
            mutateUpdatePlan,
            showSuccessNotification,
            showErrorNotification,
            email,
            isTrialPlan,
            activePlanId,
            planName,
            userSeatsQuota,
            nextBillingDateMs
        ]
    )

    const mutateStartTrial = $startTrial.mutate

    const startTrial = useCallback<StartTrialType>(
        async planId =>
            mutateStartTrial({
                email,
                planId
            }).then(() => {
                showSuccessNotification(
                    'We’ve received your request. Your trial will start shortly.'
                )
            }),
        [mutateStartTrial, email, showSuccessNotification]
    )

    const setFormLoading = formLoading.set
    const submitStripeForm = useCallback<SubmitStripeFormType>(
        async ({ elements, stripe, name, onError, onSuccess }) => {
            setBillingEmailError('')

            if (billingEmail && !yupString().email().isValidSync(billingEmail)) {
                setBillingEmailError('Must be a valid email format')
                return
            }

            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            if (!stripe || !elements) {
                setFormLoading(false)
                showErrorNotification('Something went wrong')
                return
            }

            setFormLoading(true)

            return stripe
                .confirmSetup({
                    //`Elements` instance that was used to create the Payment Element
                    elements,
                    confirmParams: {
                        return_url: window.location.href,
                        payment_method_data: {
                            billing_details: {
                                name
                            }
                        }
                    },
                    redirect: 'if_required'
                })
                .then(res => {
                    if (res.error) {
                        // Show error to your customer (e.g., payment details incomplete)
                        onError?.(res)
                        return res
                    }

                    onSuccess?.(res)
                    return res
                })
                .catch(e => {
                    console.error(e)
                    showErrorNotification('Something went wrong. Please, try again later.')
                })
                .finally(() => setFormLoading(false))

            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        },
        [billingEmail, setFormLoading, showErrorNotification]
    )

    const cardDataIsEmpty = !Boolean(stripe?.paymentMethod)
    const formIsReady =
        Boolean(stripePromise) && (Boolean(clientSecret) || Boolean(updateCardSecret))

    const isLoading =
        $updatePlan.isLoading ||
        $startTrial.isLoading ||
        $updateCard.isLoading ||
        $updateEmail.isLoading ||
        $startCheckout.isLoading ||
        formLoading.isTrue

    const isStartCheckoutLoading = $startCheckout.isLoading

    const paymentPeriod = stripe?.plan?.period || 'month'

    return useMemo(
        () => ({
            startCheckout,
            chargeCustomer,
            updatePlan,
            startTrial,
            submitStripeForm,
            startCardUpdate,
            updateEmail,
            stripePromise,
            stripe,
            clientSecret,
            updateCardSecret,
            cardDataIsEmpty,
            formIsReady,
            isLoading,
            paymentPeriod,
            paymentFailed: stripe?.paymentIntent,
            paymentMethod: stripe?.paymentMethod || ({} as PaymentMethodMapped),
            estimateSubscriptionData,
            estimateSubscription: $estimateSubscription.mutate,
            isEstimationLoading: $estimateSubscription.isLoading,
            fetchUsersToDowngrade,
            usersToDowngrade,
            applyCoupon,
            couponResData,
            isCouponApplying,
            taxes: $startCheckout.data?.taxes || [],
            setBillingEmail,
            billingEmail,
            billingEmailError,
            isStartCheckoutLoading
        }),
        [
            startCheckout,
            chargeCustomer,
            updatePlan,
            startTrial,
            submitStripeForm,
            startCardUpdate,
            updateEmail,
            stripePromise,
            stripe,
            clientSecret,
            updateCardSecret,
            cardDataIsEmpty,
            formIsReady,
            isLoading,
            paymentPeriod,
            estimateSubscriptionData,
            $estimateSubscription.mutate,
            $estimateSubscription.isLoading,
            fetchUsersToDowngrade,
            usersToDowngrade,
            applyCoupon,
            couponResData,
            isCouponApplying,
            $startCheckout.data?.taxes,
            billingEmail,
            billingEmailError,
            isStartCheckoutLoading
        ]
    )
}
