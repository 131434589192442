import { useCallback, useState } from 'react'

import { Box, Button, TextField, CircularProgress, Typography, FormHelperText } from '@mui/material'
import { getPersonalReferralCode, logToAnalytics } from 'modules'
import { type BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'

import { useBoolean } from 'hooks'

import { SpacedGroup } from 'UI/Components'

type Props = {
    onReqStart?: () => void
    onReqEnd?: () => void
    onSuccess?: (data: CouponResDataType) => void
    planId: string
    billingPeriod: BillingPeriod
    unitQuantity: number
    applyCoupon: (input: ApplyCouponProps) => Promise<CouponResDataType>
    promoCode: string
    isCouponApplying: boolean
}

export type ApplyCouponProps = {
    couponId: string | null
    planId?: string
    billingPeriod?: BillingPeriod
    unitQuantity?: number
}

export type CouponResDataType =
    | {
          couponId: string
      }
    | undefined

export const ApplyCoupon = ({
    onReqStart,
    onReqEnd,
    onSuccess,
    billingPeriod,
    planId,
    unitQuantity,
    applyCoupon,
    promoCode,
    isCouponApplying
}: Props) => {
    const showInput = useBoolean()

    const [newPromoCode, setNewPromoCode] = useState(promoCode)
    const [errorMessage, setErrorMessage] = useState('')

    const applyCouponCallback = useCallback(
        async (props: ApplyCouponProps) => {
            const eventName = props.couponId ? 'apply-coupon' : 'remove-coupon'

            const personalReferralCode = await getPersonalReferralCode()

            if (props.couponId === personalReferralCode) {
                return setErrorMessage("Can't apply your own referral promo code")
            }

            onReqStart?.()
            applyCoupon(props)
                .then(res => {
                    logToAnalytics(eventName, {
                        status: 'successful',
                        ...props
                    })

                    onSuccess?.(res)
                })
                .catch((e: Error) => {
                    logToAnalytics(eventName, {
                        message: e.message,
                        status: 'failed',
                        ...props
                    })
                    setErrorMessage(e.message || 'This code is invalid')
                })
                .finally(() => onReqEnd?.())
        },
        [applyCoupon, onReqEnd, onReqStart, onSuccess]
    )

    if (showInput.isFalse) {
        return (
            <Typography
                style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: '#2D9CDB'
                }}
                variant="caption"
                onClick={showInput.setTrue}
            >
                + Add promotion code
            </Typography>
        )
    }

    return (
        <SpacedGroup alignItems="flex-start">
            <Box width="100%">
                <TextField
                    fullWidth
                    value={newPromoCode}
                    variant="outlined"
                    placeholder="Type code"
                    size="small"
                    InputProps={{
                        style: {
                            fontSize: '14px',
                            lineHeight: 'normal',
                            background: '#F5F5F5',
                            fontStyle: newPromoCode ? 'normal' : 'italic'
                        }
                    }}
                    onChange={e => {
                        setErrorMessage('')
                        setNewPromoCode(e.target.value)
                    }}
                    {...(errorMessage && {
                        error: true
                    })}
                />

                {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </Box>

            <Button
                disableRipple
                disabled={isCouponApplying || !newPromoCode}
                onClick={() => {
                    const updateProps = promoCode
                        ? { couponId: '' }
                        : { couponId: newPromoCode, planId, billingPeriod, unitQuantity }

                    applyCouponCallback(updateProps)
                }}
                style={{ minHeight: 36 }}
                size="large"
                variant="contained"
            >
                {isCouponApplying ? <CircularProgress size={16} /> : 'APPLY'}
            </Button>
        </SpacedGroup>
    )
}
