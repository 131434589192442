import { type CallToActionType, type PlaybookModeType } from './playbook'

import { type svgArrowData } from 'UI/Routes/quick-guidde/CanvasEditor/Workspace/Shapes/svg-arrow-data'

export type SubtitlesStatusType = {
    stepId: string
    subGenerationId: string
    status: 'in_progress' | 'done' | 'applied' | 'error'
    subtitles: SubtitlesType
    audioNote: {
        text: string
        markdown: string
    }
}

type BaseAudioNoteType = {
    text: string
    markdown: string
    tempMarkdown?: string
    audioUrl: string
    audioDuration: number
    isUserEdited?: boolean
}

export type SpeechToTextType = BaseAudioNoteType & {
    type: 'speechToText'
    languageCode: string
    languageName: string
}

export type SpeakerType = {
    avatarUrl: string
    avatarUrlCompressed: string
    disabled: boolean
    gender: 'MALE' | 'FEMALE'
    id: string
    langCode: string
    langName: string
    model: string
    name: string
    s2t: boolean
    sampleAudioUrl: string
    t2s: boolean
    isStudio?: boolean
    speakingRate?: number
}

export type TextToSpeechType = BaseAudioNoteType & {
    type: 'textToSpeech'
    speakingRate?: number
    speakerConfig: SpeakerType
}

export type DefaultSubtitlesType = {
    type: 'defaultSubtitles'
} & Omit<BaseAudioNoteType, 'audioUrl' | 'audioDuration'>

export type OriginalCTA = CallToActionType | SingleCtaType['action'] | undefined

export type QgCtaActionType = Pick<
    CallToActionType,
    'enabled' | 'link' | 'image' | 'position' | 'start'
> & {
    linkType: 'guiddeLink' | 'externalLink' | 'goToStep'
    text: string
    mode: PlaybookModeType
}

export type MultipleCtaType = {
    ctaType: 'multiple'
    title: string
    actions: Array<QgCtaActionType>
}

export type SingleCtaType = {
    ctaType: 'single'
    action: QgCtaActionType
}

type EventTimeMarker = {
    name: string
    start: number
    end: number
}

type RecordingEventType = {
    id: string
    start: number
    end: number
    name: string
}

export type SubtitleType = {
    start: number
    end: number
    text: string
    error?: string
}

export type SubtitlesType = Array<SubtitleType>

export type StepType = {
    id: string
    timeStamp: number
    title: string
    type: 'click' | 'input' | 'urlChange'
    kind: 'cover' | 'step' | 'end' | 'intro' | 'outro'
    url: string
    screenshot: string
    isDuplicated?: boolean
    drawnScreenshot: string
    previewScreenshot?: string
    docScreenshot?: string
    docPublicScreenshot?: string
    docCoverScreenshot?: string
    docCoverPublicScreenshot?: string
    windowDimensions: {
        innerWidth: number
        innerHeight: number
        outerWidth: number
        outerHeight: number
    }
    layers: Array<QuickGuiddeLayerType>
    isChanged?: boolean
    duration: number
    audioNote?: SpeechToTextType | TextToSpeechType | DefaultSubtitlesType
    tempAudioNote?:
        | (Pick<SpeechToTextType, 'type' | 'languageName' | 'languageCode'> & {
              blobFile: Blob
          })
        | Pick<TextToSpeechType, 'type' | 'speakerConfig' | 'speakingRate' | 'text' | 'markdown'>
    subtitles?: SubtitlesType
    note?: string
    noteId?: string
    cta?: SingleCtaType | MultipleCtaType
    transition?: { name: string; direction?: 'left' | 'right' | 'up' | 'down'; duration: number }
    eventTimeMarkers?: EventTimeMarker
    relativeRecordingTime?: number
    recordingEvent?: RecordingEventType
}

export const enum Shape {
    Rectangle = 'rectangle',
    Circle = 'circle',
    ImageCircle = 'imageCircle',
    CircleWithText = 'circleWithText',
    Text = 'text',
    Arrow = 'arrow',
    Image = 'image',
    Overlay = 'overlay',
    Blur = 'blur',
    BrowserBar = 'browserBar',
    AudioCircle = 'audioCircle',
    Video = 'video'
}

type CanvasNodeType = {
    id: string
    x: number
    y: number
    scaleX: number
    scaleY: number
    rotation?: number
}

type BaseShapeType = CanvasNodeType & {
    strokeColor: string
    strokeWidth: number
    fill: string
}

export type TextShapeType = CanvasNodeType & {
    type: Shape.Text
    title: string
    color: string
    padding: number
    fontSize: number
    fontStyle: string
    fontFamily?: string
    textDecoration?: 'underline' | 'none'
    width?: number
    height: number
    align?: 'left' | 'center' | 'right'
    backgroundColor?: string
    isTitle?: boolean
    cornerRadius?: number
}

type RectShapeType = BaseShapeType & {
    width: number
    height: number
}

export type RectangleShapeType = Omit<RectShapeType, 'fill'> & {
    type: Shape.Rectangle
    cornerRadius: number
    isSpotlight?: boolean
    isBackground?: boolean
    fill?: string
    url?: string
    scaleFactor?: number
    fillLinearGradientColorStops?: Array<string | number>
    fillLinearGradientStartPoint?: Record<'x' | 'y', number>
    fillLinearGradientEndPoint?: Record<'x' | 'y', number>
}

export type CircleShapeType = BaseShapeType & {
    type: Shape.Circle
    radius: number
    isSpotlight?: boolean
}

export type ArrowShapeType = RectShapeType & {
    type: Shape.Arrow
    dataKey: keyof typeof svgArrowData
}

export type ImageShapeType = CanvasNodeType & {
    type: Shape.Image
    width: number
    height: number
    url: string
    originalUrl?: string
    originalScale?: number
    isBackground?: boolean
    isWatermark?: boolean
    isBrandkitLogo?: boolean
}

export type BlurShapeType = CanvasNodeType & {
    type: Shape.Blur
    width: number
    height: number
}

export type OverlayShapeType = RectShapeType & {
    type: Shape.Overlay
}

export type CircleWithTextShapeType = CanvasNodeType & {
    type: Shape.CircleWithText
    circle: CircleShapeType
    text: TextShapeType & Required<{ width: number }>
}

export type ImageCircleType = CanvasNodeType & {
    type: Shape.ImageCircle
    radius: number
    url: string
    isProfile?: boolean
}
export type BrowserBarShapeType = CanvasNodeType & {
    type: Shape.BrowserBar
    url: string
}

type ImageRenderType = {
    type: 'imageRender'
    url: string
}

type SvgRenderType = {
    type: 'svgRender'
    url: string
    logo: {
        x: number
        y: number
        fill: string
        fillRadius: number
        icon: {
            x: number
            y: number
            fill: string
            dataKey: 'qgMicrophoneIcon'
        }
    }
}

export type AudioCircleType = CanvasNodeType & {
    type: Shape.AudioCircle
    radius: number
    strokeColor: string
    strokeWidth: number
    render: ImageRenderType | SvgRenderType
}

export type VideoShapeType = CanvasNodeType & {
    type: Shape.Video
    sourceVideoUrl: string
    width: number
    height: number
    start: number
    end: number
    sourceDuration: number
    generateArtifacts: true
    sourceVideoThumbnailPreview: string
    isBackground?: boolean
}

export type QuickGuiddeLayerType =
    | RectangleShapeType
    | CircleShapeType
    | ArrowShapeType
    | TextShapeType
    | ImageShapeType
    | OverlayShapeType
    | BlurShapeType
    | CircleWithTextShapeType
    | BrowserBarShapeType
    | AudioCircleType
    | ImageCircleType
    | VideoShapeType

export type AudioBackgroundType = {
    url: string // url of the audio file
    name: string
    isEditable?: boolean
    description?: string
    img: string // url for the preview image
    id: string
    duration: number
    volume?: number
}
