import { useEffect, useRef, useState, memo, type ReactNode } from 'react'

import { useBoolean, useTextDebounce } from 'hooks'

import { Box, TextField } from '@mui/material'

const CHARS_LIMIT = 88

type Props = {
    isActive: boolean
    value: string
    placeholder?: ReactNode
    onUpdate?: (newText: string) => void
    onFocus?: () => void
}

export const CaptionText = memo(({ isActive, value, placeholder, onUpdate, onFocus }: Props) => {
    const hover = useBoolean()

    const [text, setText] = useState(value)
    const debouncedText = useTextDebounce(text, 200)

    const isInitialMount = useRef(true)
    const initialValue = useRef(value)
    const lastUpdatedValue = useRef(value)

    useEffect(() => {
        // Ignore the initial update of debouncedText
        if (isInitialMount.current) {
            if (debouncedText === initialValue.current) {
                isInitialMount.current = false
            }
            return
        }

        if (debouncedText !== lastUpdatedValue.current) {
            lastUpdatedValue.current = debouncedText
            onUpdate?.(debouncedText)
        }
    }, [debouncedText, onUpdate])

    useEffect(() => {
        if (value !== lastUpdatedValue.current) {
            setText(value)
            initialValue.current = value
            lastUpdatedValue.current = value
            isInitialMount.current = true
        }
    }, [value])

    return (
        <Box onMouseEnter={hover.setTrue} onMouseLeave={hover.setFalse}>
            {hover.isTrue || isActive ? (
                <CaptionTextField
                    isActive={isActive}
                    value={text}
                    onChange={e => setText(e.target.value)}
                    onFocus={onFocus}
                />
            ) : (
                placeholder
            )}
        </Box>
    )
})

export const CaptionTextField = ({
    isActive,
    value,
    onChange,
    onFocus,
    onBlur
}: {
    isActive?: boolean
    value?: string
    onChange?: (e: any) => void
    onFocus?: () => void
    onBlur?: (e: any) => void
}) => {
    const [charCount, setCharCount] = useState(value?.length || 0)
    const focus = useBoolean()

    const isOverCharsLimit = charCount > CHARS_LIMIT

    return (
        <>
            <TextField
                value={value}
                onChange={e => {
                    setCharCount(e.target.value.length)
                    onChange?.(e)
                }}
                onFocus={() => {
                    onFocus?.()
                    focus.setTrue()
                }}
                onBlur={e => {
                    onBlur?.(e)
                    focus.setFalse()
                }}
                sx={theme => ({
                    '&:hover': {
                        ...(isActive || focus.isTrue
                            ? {}
                            : {
                                  borderRight: `2px solid ${theme.palette.primary['300']}`,
                                  background: theme.palette.primary['50']
                              })
                    },
                    '& .MuiInputBase-input': {
                        '&::placeholder': {
                            color: theme.palette.grey['400'],
                            opacity: 1
                        }
                    },
                    '& .MuiInputBase-root': {
                        color:
                            isOverCharsLimit && !isActive
                                ? theme.palette.error['700']
                                : theme.palette.grey['600'],
                        borderRadius: '8px',
                        ...(isActive || focus.isTrue
                            ? {
                                  padding: '10px 13px',
                                  border: isOverCharsLimit
                                      ? `1px solid ${theme.palette.error['400']}`
                                      : '1px solid #D0D5DD',
                                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
                              }
                            : {
                                  padding: 0,
                                  border: 'none',
                                  boxShadow: 'none'
                              })
                    },
                    '& fieldset': { display: 'none' }
                })}
                variant="outlined"
                fullWidth
                multiline
                placeholder="Add captions"
            />
            {isActive && (
                <Box
                    display="flex"
                    justifyContent={'flex-end'}
                    sx={theme => ({
                        marginTop: '8px !important',
                        color: isOverCharsLimit
                            ? theme.palette.error['700']
                            : theme.palette.grey['600']
                    })}
                >
                    {charCount}/88
                </Box>
            )}
        </>
    )
}
