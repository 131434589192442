import { memo, useContext, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Typography } from '@guidde/design-system'

import { Box, CircularProgress, Stack, Typography as MuiTypography } from '@mui/material'

import { SmartPreviewContext } from 'UI/Routes/quick-guidde/CanvasEditor'
import { CaptionTime } from './CaptionTime'
import { CaptionBlock } from './CaptionBlock'
import { EmptyCaptionBlock } from './EmptyCaptionBlock'

import { type SubtitlesType, type SubtitleType } from 'app/types'
import { setActiveStep, setStepSubtitlesBlock } from 'ducks'
import { delay, logToAnalytics } from 'modules'

type Props = {
    subtitles: SubtitlesType
    stepIndex: number
    stepTitle: string
    stepTimeDelta: number
    stepDuration: number
    isActive: boolean
    isStepProcessing: boolean
}

export const CaptionsStep = memo(
    ({
        subtitles,
        stepTitle,
        stepIndex,
        stepTimeDelta,
        stepDuration,
        isActive,
        isStepProcessing
    }: Props) => {
        const { playbookId } = useParams<{ playbookId: string }>()

        const stepCaptionsRef = useRef<HTMLDivElement>(null)

        const { setTime } = useContext(SmartPreviewContext)

        const dispatch = useDispatch()

        const stepStart = subtitles?.[0]?.start || 0

        const onUpdateBlock = (blockIndex: number, newProperties: Partial<SubtitleType>) => {
            if (!subtitles?.[blockIndex]) return null

            logToAnalytics('captions_editor_update', {
                playbookId,
                stepIndex,
                blockIndex,
                newProperties
            })

            dispatch(
                setStepSubtitlesBlock({
                    stepIndex,
                    blockIndex,
                    operation: 'update',
                    subtitlesBlock: { ...subtitles[blockIndex], ...newProperties }
                })
            )
        }

        const onAddBlock = (blockIndex: number, text?: string) => {
            const currentBlock = subtitles?.[blockIndex]
            const start = currentBlock?.end || 0
            const end = start + 1

            const subtitlesBlock = {
                start,
                end,
                text: text || ''
            }

            const newBlockIndex = blockIndex + (currentBlock ? 1 : 0)

            logToAnalytics('captions_editor_add', {
                playbookId,
                stepIndex,
                blockIndex: newBlockIndex,
                subtitlesBlock
            })

            return dispatch(
                setStepSubtitlesBlock({
                    stepIndex,
                    operation: 'add',
                    blockIndex: newBlockIndex,
                    subtitlesBlock
                })
            )
        }

        const onDeleteBlock = (blockIndex: number) => {
            logToAnalytics('captions_editor_delete', {
                playbookId,
                stepIndex,
                blockIndex
            })

            dispatch(
                setStepSubtitlesBlock({
                    stepIndex,
                    blockIndex,
                    operation: 'delete'
                })
            )
        }

        // Workaround according to https://github.com/facebook/react/issues/23396
        useEffect(() => {
            if (!isActive || !stepCaptionsRef.current) return

            const animationFrame = requestAnimationFrame(() => {
                stepCaptionsRef.current?.scrollIntoView({
                    behavior: 'smooth'
                })
            })
            return () => {
                cancelAnimationFrame(animationFrame)
            }
        }, [isActive, stepIndex])

        return (
            <>
                <Stack
                    ref={stepCaptionsRef}
                    spacing={2}
                    p={2}
                    pl={2.5}
                    pr={1.5}
                    sx={theme => ({
                        cursor: isActive ? 'default' : 'pointer',
                        borderTop: `3px solid ${theme.palette.grey['200']}`,
                        borderBottom: `1px solid ${theme.palette.grey['200']}`,
                        ...(isActive && {
                            boxShadow: `inset 4px 0 0 ${theme.palette.primary['600']}`
                        })
                    })}
                    onClick={() => {
                        if (isActive) return
                        dispatch(setActiveStep(stepIndex))
                        setTime(stepTimeDelta + (subtitles?.[0]?.start || 0) + 0.1)
                    }}
                >
                    <Typography variant="text" size="md" fontWeight="semibold">
                        {stepTitle}
                    </Typography>

                    <CaptionTime
                        isEditable={false}
                        isActive={false}
                        start={stepTimeDelta + stepStart}
                        end={stepTimeDelta + stepDuration}
                    />

                    {isStepProcessing && (
                        <Box mb={2} textAlign="center">
                            <CircularProgress size={55} />
                            <MuiTypography variant="body2" fontWeight="bold">
                                Creating step captions
                            </MuiTypography>
                        </Box>
                    )}
                </Stack>

                {!isStepProcessing && (
                    <>
                        {subtitles.length ? (
                            subtitles.map((caption, index) => {
                                return (
                                    <CaptionBlock
                                        blockId={`${stepIndex}-${index}`}
                                        key={stepIndex + index}
                                        caption={caption}
                                        isInitiallyActive={false}
                                        timeDelta={stepTimeDelta}
                                        onUpdate={props => onUpdateBlock(index, props)}
                                        onAdd={() => {
                                            onAddBlock(index)

                                            delay(200).then(() => {
                                                const newId = `${stepIndex}-${index + 1}`
                                                const elem = document.getElementById(newId)

                                                if (!elem) return
                                                elem.querySelector('textarea')?.focus()
                                            })
                                        }}
                                        onDelete={() => onDeleteBlock(index)}
                                        onClick={() => setTime(stepTimeDelta + caption.start + 0.1)}
                                    />
                                )
                            })
                        ) : (
                            <EmptyCaptionBlock
                                onAdd={text => {
                                    if (!text) return
                                    onAddBlock(0, text)
                                }}
                            />
                        )}
                    </>
                )}
            </>
        )
    }
)
