import { generatePath } from 'react-router-dom'

import { getAuth } from 'firebase/auth'

import short from 'short-uuid'
import dayjs from 'dayjs'
import { type Location } from 'history'

import {
    type QuickGuiddeType,
    type AnyPlaybookType,
    type PlaylistType,
    PlaybookMode
} from 'app/types'
import { paths } from 'app/paths'

import { envConfig } from './envConfig'

export const isDev =
    envConfig.firebaseConfig.projectId !== 'guidde-production' &&
    envConfig.firebaseConfig.projectId !== 'guidde-production-eu'
// export const isLocalhost = import.meta.env.DEV
export const isLocalhost = process.env.NODE_ENV === 'development'

export const uuid = short.generate

export const delay = (time: number): Promise<void> =>
    // @ts-ignore
    new Promise(resolve => setTimeout(resolve, time)).then(clearTimeout)

export const host = `https://${envConfig.firebaseConfig.authDomain}`

export const fetchData = (url: string, requestData: any): any =>
    fetch(`${host}${url}`, requestData).then(res => res.json())

export const fetchBlobData = async (url: string, body: any) => {
    const token = await getAuth().currentUser?.getIdToken()

    const res = await fetch(`${host}${url}`, {
        method: 'POST',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    const blob = await res.blob()
    return URL.createObjectURL(blob)
}

export const generalErrorMessage = 'Something went wrong. Please try again later'

export const request = async (
    url: string,
    method: string,
    body?: any,
    tokenRetries: number = 3
): Promise<any> => {
    const token = await getAuth().currentUser?.getIdToken()

    // Stop recursion after 3 retries
    if (!token && !tokenRetries) return

    // On page load we don't have user initialized so let's wait and try again
    if (!token) {
        return delay(1000).then(() => request(url, method, body, tokenRetries - 1))
    }

    return fetch(`${host}${url}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'x-guidde-client': 'webapp',
            authorization: `Bearer ${token}`
        },
        ...(body && { body: JSON.stringify(body) })
    }).then(handleRequest(method))
}

export const handleRequest = (method: string) => async (res: any) => {
    const errorObject = { code: res.status, message: generalErrorMessage, method, url: res.url }
    const contentType = res.headers.get('content-type')

    if (!contentType?.includes('application/json')) {
        throw errorObject
    } else {
        const json = await res.json()

        if (!res.ok) {
            throw json ? { ...json, code: res.status } : errorObject
        }

        return json
    }
}

export const option = (value: string, label: string) => ({ value, label })

export const objectOption = <T>(value: T, label: string) => ({
    value,
    label
})

export type OptionType = ReturnType<typeof option>

export const uniqueArray = <T>(a: Array<T>): Array<T> =>
    [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

export const round = (number: number) => Math.round(number * 100) / 100

export const humanFileSize = (size: number) => {
    if (!size) return '0 B'

    const i = Math.floor(Math.log(size) / Math.log(1024))

    return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

export const secondsToTime = (totalSeconds: number) => {
    return {
        hours: Math.floor(totalSeconds / 3600),
        minutes: Math.floor((totalSeconds % 3600) / 60),
        seconds: Math.floor(totalSeconds % 60)
    }
}

export const formatTime = (totalSeconds: number, showHours = false) => {
    const format = (val: number) => `0${val}`.slice(-2)

    const { hours, seconds, minutes } = secondsToTime(totalSeconds)

    const h = hours >= 1 || showHours ? [hours] : []

    return [...h, minutes, seconds].map(format).join(':')
}

export const formatDate = (seconds: number) => {
    return new Date(seconds * 1000).toString().substring(4, 16)
}

export const timeToSeconds = (time: any) => {
    if (!time) return 0

    return time
        .split(':')
        .reverse()
        .reduce((prev: number, curr: number, i: number) => prev + curr * Math.pow(60, i), 0)
}

export const tagRegex = new RegExp(/^[a-zA-Z0-9_.-]*$/)

export const roundToHundredth = (value: number) => Number(value.toFixed(2))

// Extend the Navigator type to include Brave-specific properties
declare global {
    interface Navigator {
        brave?: {
            isBrave?: () => Promise<boolean>
        }
    }
}

export const isBraveBrowser = async () => {
    if (window.navigator.brave && (await window.navigator.brave.isBrave?.())) {
        return true
    }
    return false
}

export const getUserBrowser = () => {
    const sUsrAg = navigator.userAgent
    let sBrowser = 'unknown'

    if (sUsrAg.indexOf('Firefox') > -1) {
        sBrowser = 'firefox'
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
        sBrowser = 'samsung_internet'
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
        sBrowser = 'opera'
    } else if (sUsrAg.indexOf('Trident') > -1) {
        sBrowser = 'ie'
    } else if (sUsrAg.indexOf('Edg') > -1 || sUsrAg.indexOf('Edge') > -1) {
        sBrowser = 'edge'
    } else if (sUsrAg.indexOf('Chrome') > -1) {
        sBrowser = 'chromium'
    } else if (sUsrAg.indexOf('Safari') > -1) {
        sBrowser = 'safari'
    }
    return sBrowser
}

export const getExtUrl = (forBrowser?: string) => {
    const browser = forBrowser || getUserBrowser()
    const env = envConfig.firebaseConfig.projectId
    const map = {
        edge: {
            'guidde-production':
                'https://microsoftedge.microsoft.com/addons/detail/pldeojbhedianlcaejpfbnckfhkacphe',
            'guidde-dev-staging':
                'https://microsoftedge.microsoft.com/addons/detail/guidde-dev/hocgfmngoiokpfpanmilkknmgoehhien',
            default:
                'https://microsoftedge.microsoft.com/addons/detail/pldeojbhedianlcaejpfbnckfhkacphe'
        },
        chromium: {
            'guidde-production':
                'https://chrome.google.com/webstore/detail/guidde/oacmmmjedhheaijfjidilonpngccnhdl',
            'guidde-dev-staging':
                'https://chrome.google.com/webstore/detail/guidde-stg/jgnijfadmjdidipjnbbmnimpmbiippml',
            default:
                'https://chrome.google.com/webstore/detail/guidde/oacmmmjedhheaijfjidilonpngccnhdl'
        },
        default: {
            default:
                'https://microsoftedge.microsoft.com/addons/detail/pldeojbhedianlcaejpfbnckfhkacphe'
        }
    }
    //@ts-ignore
    const chosenBrowser = map[browser] || map.default

    return chosenBrowser[env] || chosenBrowser.default
}

export const generateSpaceIds = (spaces: Array<string>) => {
    return spaces.filter(it => it !== 'mainSpace')
}

export const openLink = (link: string, type: '_blank' | '_self' = '_blank') => {
    window.open(link, type)
}

export const calculateDaysLeft = (endDay: number, startDay: number = Date.now()) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number
    const days = Math.round((endDay - startDay) / (1000 * 60 * 60 * 24))
    return !isNaN(days) ? days : ''
}

export const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export const copyToClipboard = (text?: string) => navigator.clipboard.writeText(text || '')

export const containsEachValue = (arr: Array<unknown>, arr2: Array<unknown>) =>
    arr2?.every(it => arr?.includes(it))

export const getDateUntilNow = (days: number) => {
    const startDate = dayjs().subtract(days, 'day').format('DD MMM, YYYY')
    const endDate = dayjs().format('DD MMM, YYYY')

    return `${startDate} → ${endDate}`
}

export const isColorLight = (color: string) => {
    const hex = color.replace('#', '')
    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    return brightness > 155
}

export const filterURLParams = (location: Location, keys: Array<string>) => {
    const newSearch = new URLSearchParams(
        Object.fromEntries(
            Array.from(new URLSearchParams(location.search).entries()).filter(
                ([key]) => !keys.includes(key)
            )
        )
    ).toString()

    return `${location.pathname}?${newSearch}`
}

export const isDeepEqual = (object1: any, object2: any) => {
    const isObject = (object: any) => {
        return object != null && typeof object === 'object'
    }

    if (!isObject(object1) || !isObject(object2)) return false

    const objKeys1 = Object.keys(object1)
    const objKeys2 = Object.keys(object2)

    if (objKeys1.length !== objKeys2.length) return false

    for (let key of objKeys1) {
        const value1 = object1[key]
        const value2 = object2[key]

        const isObjects = isObject(value1) && isObject(value2)

        if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
            return false
        }
    }
    return true
}

export const getTimeString = (time: number) => {
    const secs = `${parseInt(`${time % 60}`, 10)}`.padStart(2, '0')
    const min = `${parseInt(`${(time / 60) % 60}`, 10)}`.padStart(2, '0')

    return `${min}:${secs}`
}

export const isQG = (playbook: AnyPlaybookType): playbook is QuickGuiddeType => {
    return playbook.mode === PlaybookMode.QuickGuidde
}

export const isPlaylist = (playbook: AnyPlaybookType): playbook is PlaylistType => {
    return playbook.mode === PlaybookMode.Playlist
}

export const isValidUrl = (urlString: string) => {
    const urlPattern = new RegExp('^(https?|ftp):\\/\\/(\\S+\\.)*\\S+\\.\\S+$')
    return Boolean(urlString.match(urlPattern))
}

export const getTextWidth = (text: string, fontSize: string = '14px') => {
    const tag = document.createElement('div')
    tag.style.position = 'absolute'
    tag.style.zIndex = '0'
    tag.style.left = '-99in'
    tag.style.whiteSpace = 'nowrap'
    tag.style.fontSize = fontSize
    tag.innerHTML = text

    document.body.appendChild(tag)
    const result = tag.clientWidth
    document.body.removeChild(tag)

    return result
}

export const getPlaybookPath = ({ isPublic, mode, id }: AnyPlaybookType) => {
    if (mode === PlaybookMode.Playlist) {
        return generatePath(isPublic ? paths.publicPlaylist : paths.playlistDetails, {
            playbookId: id
        })
    }

    return generatePath(isPublic ? paths.publicPlaybook : paths.playbookDetails, {
        playbookId: id
    })
}

export const containsRTL = (language: string) => {
    const rtlLanguages = ['ar-DZ', 'iw-IL']
    return rtlLanguages.includes(language)
}

// Regular expression for color code validation
export const colorCodeRegex = /^#[0-9A-Fa-f]{6}$/

export const extractObjectFromString = (str: string) => {
    if (typeof str !== 'string') return null

    const regex = /{.*}/
    const match = str.match(regex)

    if (!match) return null

    try {
        return JSON.parse(match[0])
    } catch (e) {
        return null
    }
}

export const bytesToMB = (bytes: number) => bytes / (1024 * 1024)

export const getFirebaseUrl = async (
    storagePath: string,
    isTempStorage?: boolean
): Promise<string> => {
    // Direct firebase url (via getDownloadURL) is forbidden for some of our customers, so we use a custom url
    return await request('/c/v1/file/download-url', 'POST', {
        filename: storagePath,
        isTempStorage
    }).then(res => res.url)
}

export const getAudioDuration = async (input: string | File): Promise<number> => {
    const readBlobAsArrayBuffer = (blob: Blob): Promise<ArrayBuffer> => {
        const reader = new FileReader()

        return new Promise<ArrayBuffer>((resolve, reject) => {
            reader.onloadend = () => {
                if (reader.result instanceof ArrayBuffer) resolve(reader.result)
                else reject(new Error('Could not read file as ArrayBuffer'))
            }
            reader.onerror = reject
            reader.readAsArrayBuffer(blob)
        })
    }

    const audioContext = new AudioContext()
    let arrayBuffer: ArrayBuffer | null = null

    const isString = typeof input === 'string'
    if (isString) arrayBuffer = await fetch(input).then(res => res.arrayBuffer())

    const isFile = input instanceof File
    if (isFile) arrayBuffer = await readBlobAsArrayBuffer(input)

    if (!arrayBuffer) throw new Error('Input must be a string (URL), or a File')

    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
    const duration = audioBuffer.duration

    audioContext.close()
    return duration
}
