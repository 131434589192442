import { Box } from '@mui/material'

import { CaptionTextField } from './CaptionText'

type Props = {
    onAdd: (_newText: string) => void
}

export const EmptyCaptionBlock = ({ onAdd }: Props) => {
    return (
        <Box p={2} pl={2.5} pr={1.5}>
            <CaptionTextField onBlur={e => onAdd(e.target.value)} />
        </Box>
    )
}
