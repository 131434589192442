import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'

import { ImageCropper } from 'UI/Components'

import { type StepType, Shape } from 'app/types'

import { useAuth } from 'hooks'
import { uuid } from 'modules'
import { setBackgroundLayer } from 'ducks'

type Props = {
    applyToAll: boolean
    layers: StepType['layers']
    windowDimensions: StepType['windowDimensions']
}

export const ImageUploader = ({ layers, applyToAll, windowDimensions }: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const { uid } = useAuth()

    // Do not allow to upload new background image in case of "complex background" and video step
    const disableUploadImage = layers.some(layer =>
        [Shape.BrowserBar, Shape.Video].includes(layer.type)
    )

    const { innerWidth, innerHeight } = windowDimensions

    if (disableUploadImage) return null

    return (
        <ImageCropper
            aspectRatio={innerWidth / innerHeight}
            isTempStorage
            storagePath={`imageUploads/${uid}/${playbookId}_${uuid()}`}
            labelComponent={
                <Box
                    width={28}
                    height={28}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CropOriginalIcon fontSize="small" />
                </Box>
            }
            onDone={({ url, width, height }) => {
                dispatch(
                    setBackgroundLayer(
                        {
                            type: Shape.Image,
                            x: 0,
                            y: 0,
                            scaleX: innerWidth / width,
                            scaleY: innerHeight / height,
                            width,
                            height,
                            isBackground: true,
                            id: uuid(),
                            url
                        },
                        applyToAll
                    )
                )
            }}
        />
    )
}
